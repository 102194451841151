<template>
  <div class="container" v-if="!isSoaReceiverExist && !isB2cCustomer">
    <ion-grid>
      <ion-row>
        <ion-col size="1">
          <ion-row>
            <ion-icon color="danger" class="f-icon" :icon="alertCircleOutline"></ion-icon>
          </ion-row>
        </ion-col>
        <ion-col size="11">
          <ion-row>
            <ion-label class="f-bold">
              {{ $t('customerPage.customer_does_not_have_soa_receiver') }}
            </ion-label>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>
<script>
import { apolloClient } from '@/main';
import { saleGetCustomerDetail } from '@/modules/sale/services/graphql';
import { alertController } from '@ionic/vue';
import { alertCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SoaReceiverNotif',
  inject: ['$storage'],
  setup() {
    return {
      alertCircleOutline
    };
  },
  data() {
    return {
      selectedCompany: null,
      isSoaReceiverExist: false
    };
  },
  async mounted() {
    await this.reloadSelectedCompany();
  },
  computed: {
    isB2cCustomer() {
      return !!this.selectedCompany?.is_b2c;
    }
  },
  methods: {
    async presentAlert(message) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Alert',
        message: message,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    },
    async loadCustomerData() {
      try {
        if (!this.selectedCompany) return false;
        const { data } = await apolloClient.query({
          query: saleGetCustomerDetail,
          variables: {
            id: this.selectedCompany.id
          }
        });
        const customer = data.saleGetCustomerDetail;
        const customerPic = customer.persons;

        if (customerPic.length === 0) {
          this.isSoaReceiverExist = false;
        } else {
          const soaReceiverPersons = customerPic.filter((person) => person.is_soa_receiver === true);
          this.isSoaReceiverExist = soaReceiverPersons.length > 0;
        }
      } catch (error) {
        this.presentAlert(error);
      }
    },
    async reloadSelectedCompany() {
      const selectedCompany = await this.$storage.getSelectedCompany();
      if (!selectedCompany) return;

      this.selectedCompany = selectedCompany;
      if (selectedCompany.is_b2c) return;

      await this.loadCustomerData();
    }
  }
});
</script>
<style lang="scss" scoped>
.container {
  margin-top: 5px;
  border-top: 1px solid #fda29b;
  border-bottom: 1px solid #fda29b;
  padding: 0.4rem;
  z-index: 1;
  background: #fef3f2;
}
.f-bold {
  font-weight: 600;
}
.f-icon {
  font-size: 1.6rem;
}
ion-label {
  margin-bottom: 0.5rem;
}
ion-button {
  margin-left: -1px;
  width: fit-content;
  height: 26px;
  text-transform: capitalize;
  ion-label {
    margin-bottom: 0;
    font-size: 13px;
  }
}
</style>
